import React from 'react'
import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const ExternalLink = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M13.0312 2.96875V11.9688H11.5312V5.53125L4.03125 13.0312L2.96875 11.9688L10.4688 4.46875H4.03125V2.96875H13.0312Z" />
    </SvgIcon>
  )
}

export default ExternalLink
